import React, { useState, useEffect, useContext } from 'react'
import { FaChevronRight } from 'react-icons/fa'
import Loader from 'react-loader-spinner'

import { useAuth0 } from '../../react-auth0-spa'
import SubscriptionContext from '../../context/subscription/subscriptionContext'
import UserContext from '../../context/user/userContext'
import styles from './subscriptions.module.scss'
import DevicesIcon from '../../images/png/start_devices.png'
import MicrophoneIcon from '../../images/png/start_microphone.png'
import WorldTwoIcon from '../../images/png/start_world_2.png'
import FamilyIcon from '../../images/png/start_family.png'
import DesktopIcon2 from '../../images/png/desktop.png'
import AndroidIcon from '../../images/png/android.png'
import AppleIcon from '../../images/png/apple.png'
import IdealIcon from '../../images/png/ideal.png'

const FLUENT_WORLDS_INDEX = 0
const PERFECT_ACCENT_INDEX = 1
const PAQUETE_PRO_INDEX = 2
const FAMILIA_INDEX = 3
const FLUENTWORLDS_MONTHLY_COP_NAME = 'FluentWorlds Monthly COP'
const FLUENTWORLDS_MONTHLY_COP_PRICE = '$36,000 COP / mes'
const FLUENTWORLDS_ANNUAL_COP_NAME = 'FluentWorlds Annual COP'
const FLUENTWORLDS_ANNUAL_COP_PRICE = '$360,000 COP / año'
const PERFECT_ACCENT_NAME = 'perfectAccent'
const PAQUETE_PRO_NAME = 'paquetePro'
const FAMILIA_NAME = 'familia'

// Loading spinner when user click the Comprar button
const Spinner = () => (
  <Loader
    type="Oval"
    color="#FFF"
    height={20}
    width={20}
    timeout={30000} // 30 second timeout
  />
)

const Subscriptions = () => {
  const {
    fetchAvailableSitePlans,
    createStripeSubscription,
    createBillingPortalSession,
    subscriptions,
    subscriptionStateLoading,
  } = useContext(SubscriptionContext)

  const { productAccess, fetchUserProductAccess } = useContext(UserContext)

  const { isAuthenticated } = useAuth0()
  const [selectedSubscription, setSelectedSubscription] = useState('')
  const [selectedSubscriptionHover, setSelectedSubscriptionHover] = useState(
    PAQUETE_PRO_INDEX,
  )

  // when the user is logged in and subscriptions haven't yet been fetched then fetch the available site plans
  useEffect(() => {
    if (isAuthenticated && subscriptions.length === 0) {
      fetchAvailableSitePlans()
      if (!productAccess) {
        fetchUserProductAccess()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, productAccess])

  const onSubscriptionHover = index => {
    if (selectedSubscriptionHover !== index) {
      setSelectedSubscriptionHover(index)
    }
  }

  // only allow Stripe Checkout flow when user is authenticated
  const onSubscriptionClick = async subscriptionName => {
    setSelectedSubscription(subscriptionName)

    if (isAuthenticated) {
      if (productAccess && !productAccess.fluentworlds) {
        createStripeSubscription(subscriptionName)
      } else {
        const billingSessionUrl = await createBillingPortalSession()

        // redirect to the stripe self serve billing portal
        if (typeof window !== 'undefined' && billingSessionUrl !== undefined) {
          window.location.replace(billingSessionUrl)
        }
      }
    } else {
      window.alert('Debe iniciar sesión para comprar una suscripción')
    }
  }

  const isFluentWorldsMonthlyButtonLoading =
    (subscriptionStateLoading &&
      selectedSubscription === FLUENTWORLDS_MONTHLY_COP_NAME) ||
    (isAuthenticated && !productAccess)

  const isFluentWorldsAnnualButtonLoading =
    (subscriptionStateLoading &&
      selectedSubscription === FLUENTWORLDS_ANNUAL_COP_NAME) ||
    (isAuthenticated && !productAccess)

  const isSubscriptionButtonManage = productAccess && productAccess.fluentworlds

  return (
    <div className={styles.subscriptionsContainer}>
      <div
        className={
          selectedSubscriptionHover === FLUENT_WORLDS_INDEX
            ? `${styles.subscription} ${styles.subscriptionSelected}`
            : styles.subscription
        }
        onMouseEnter={() => onSubscriptionHover(FLUENT_WORLDS_INDEX)}
        role="presentation"
      >
        <img src={DevicesIcon} alt="devices" className={styles.sectionIcon} />
        <h4>FluentWorlds</h4>
        <span className="line-break-3"></span>
        <p>
          Aprende inglés en un sistema inmersivo en 3D, explorando escenarios
          cotidianos.
        </p>
        <h5>Úsala en</h5>
        <div className={styles.smallIconContainer}>
          <img src={DesktopIcon2} alt="desktop" className={styles.smallIcon} />

          <img src={AndroidIcon} alt="android" className={styles.smallIcon} />

          <img src={AppleIcon} alt="apple" className={styles.smallIcon} />
        </div>
        <div className={styles.buttonContainer}>
          <button
            className={styles.disabledButton}
            disabled
            onClick={() => onSubscriptionClick(PERFECT_ACCENT_NAME)}
          >
            Disponible Pronto
          </button>
        </div>
      </div>
      <div
        className={
          selectedSubscriptionHover === PERFECT_ACCENT_INDEX
            ? `${styles.subscription} ${styles.subscriptionSelected}`
            : styles.subscription
        }
        onMouseEnter={() => onSubscriptionHover(PERFECT_ACCENT_INDEX)}
        role="presentation"
      >
        <img
          src={MicrophoneIcon}
          alt="microphone"
          className={styles.sectionIcon}
        />
        <h4>PerfectAccent</h4>
        <span className="line-break-3"></span>
        <p>
          Perfecciona tu pronunciación y amplía tu vocabulario especializado.
        </p>
        <h5>Úsala en</h5>
        <div className={styles.smallIconContainer}>
          <img src={DesktopIcon2} alt="desktop" className={styles.smallIcon} />

          <img src={AndroidIcon} alt="android" className={styles.smallIcon} />

          <img src={AppleIcon} alt="apple" className={styles.smallIcon} />
        </div>
        <div className={styles.buttonContainer}>
          <button
            className={styles.disabledButton}
            disabled
            onClick={() => onSubscriptionClick(PERFECT_ACCENT_NAME)}
          >
            Disponible Pronto
          </button>
        </div>
      </div>

      <div
        className={
          selectedSubscriptionHover === PAQUETE_PRO_INDEX
            ? `${styles.subscription} ${styles.subscriptionSelected}`
            : styles.subscription
        }
        onMouseEnter={() => onSubscriptionHover(PAQUETE_PRO_INDEX)}
        role="presentation"
      >
        <img src={WorldTwoIcon} alt="world" className={styles.sectionIcon} />

        <h4>Paquete Pro</h4>
        <span className="line-break-3"></span>
        <ul>
          <li>
            <FaChevronRight className={styles.chevronIcon} />
            Fluent Worlds
          </li>
          <li>
            <FaChevronRight className={styles.chevronIcon} />
            Perfect Accent
          </li>
          <li>
            <FaChevronRight className={styles.chevronIcon} />
            Academy
          </li>
        </ul>
        <h5>Úsala en</h5>
        <div className={styles.smallIconContainer}>
          <img src={DesktopIcon2} alt="desktop" className={styles.smallIcon} />

          <img src={AndroidIcon} alt="android" className={styles.smallIcon} />

          <img src={AppleIcon} alt="apple" className={styles.smallIcon} />
        </div>
        <div className={styles.buttonContainer}>
          <button
            className={styles.disabledButton}
            disabled
            onClick={() => onSubscriptionClick(PAQUETE_PRO_NAME)}
          >
            Disponible Pronto
          </button>
        </div>
        <img src={IdealIcon} alt="ideal" className={styles.sectionIdealIcon} />
      </div>

      <div
        className={
          selectedSubscriptionHover === FAMILIA_INDEX
            ? `${styles.subscription} ${styles.subscriptionSelected}`
            : styles.subscription
        }
        onMouseEnter={() => onSubscriptionHover(FAMILIA_INDEX)}
        role="presentation"
      >
        <img src={FamilyIcon} alt="family" className={styles.sectionIcon} />
        <h4>Familia x6</h4>
        <span className="line-break-3"></span>
        <ul>
          <li>
            <FaChevronRight className={styles.chevronIcon} />
            Fluent Worlds
          </li>
          <li>
            <FaChevronRight className={styles.chevronIcon} />
            Perfect Accent
          </li>
          <li>
            <FaChevronRight className={styles.chevronIcon} />
            Academy
          </li>
        </ul>
        <h5>Úsala en</h5>
        <div className={styles.smallIconContainer}>
          <img src={DesktopIcon2} alt="desktop" className={styles.smallIcon} />

          <img src={AndroidIcon} alt="android" className={styles.smallIcon} />

          <img src={AppleIcon} alt="apple" className={styles.smallIcon} />
        </div>
        <div className={styles.buttonContainer}>
          <button
            className={styles.disabledButton}
            disabled
            onClick={() => onSubscriptionClick(FAMILIA_NAME)}
          >
            Disponible Pronto
          </button>
        </div>
      </div>
    </div>
  )
}

export default Subscriptions
